new WOW({ offset: 70 }).init();

function loadSliders() {
	$('.clients__gallery').slick({
		centerMode: false,
		slidesToShow: 5,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.testimonials__panel').slick({
		centerPadding: '0',
		slidesToShow: 5,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	if ($("#HomepageMarker").length === 0) {
	}
	else {
		if (window.matchMedia('(min-width:768px)').matches) {
			$(".header__logo").addClass("wow fadeIn").attr({ "data-wow-duration": "1.5s", "data-wow-delay": "0.3s" });
		}
	}

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixHeights() {
		$('.testimonials__link').sameHeight();
		$('.featured-categories__title').sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on("resize",
		function () {
			setTimeout(fixHeights, 300);
		});

});



